import x from "./assets/X.webp";
import telegram from "./assets/tg.png";
import dex from "./assets/dex.png";
import pump from "./assets/pump.png";
import "./App.css";

function App() {

  const contractAddress = "9D1Ru4j1nCDedB8GxAzTsN5vEo7Yx6tX5424SydEpump";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      alert("Contract address copied to clipboard!");
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  };
  return (
    <div className="App">
      <header className="App-header">
        <h1>Tartan</h1>
        <p>Where Crypto Meets Cat-titude!</p>
        <div className="links">
          {/* <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} width="80" height="80" />
          </a> */}
              {/* <a
            className="App-link"
            href="https://dexscreener.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={dex} width="85" height="85" />
          </a> */}
          <a
            className="App-link"
            href="https://pump.fun/9D1Ru4j1nCDedB8GxAzTsN5vEo7Yx6tX5424SydEpump"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pump} width="85" height="85" />
          </a>
          <a
            className="App-link"
            href="https://t.me/tartanmeme"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={telegram} width="90" height="90" />
          </a>
        </div>
        <div>
          <p className="ca" onClick={copyToClipboard} > CA : {contractAddress}</p>
        </div>
      </header>
    </div>
  );
}

export default App;
